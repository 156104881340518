/**
 * @file App.js
 * @description Main application component handling routing and layout structure.
 */

import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout.js";
import "./css/App.css";

const pages = {
  Home: React.lazy(() => import("./pages/Home.js")),
  Services: React.lazy(() => import("./pages/Services.js")),
  About: React.lazy(() => import("./pages/AboutUs.js")),
  Contact: React.lazy(() => import("./pages/ContactUs.js")),
  NursingContracts: React.lazy(() => import("./pages/NursingContracts.js")),
  Account: React.lazy(() => import("./pages/Account.js")),
  ContractDescription: React.lazy(
    () => import("./pages/ContractDescription.js")
  ),
  ReqApplicationForm: React.lazy(
    () => import("./components/ReqApplicationForm.js")
  ),
  NotFound: React.lazy(() => import("./pages/NotFound.js")),
};

export default function App() {
  return (
    <Layout>
      <Suspense fallback={<div className="loading">Loading...</div>}>
        <Routes>
          <Route path="/" element={<pages.Home />} />
          <Route path="/services" element={<pages.Services />} />
          <Route path="/about" element={<pages.About />} />
          <Route path="/contact" element={<pages.Contact />} />
          <Route
            path="/nursing-contracts"
            element={<pages.NursingContracts />}
          />
          <Route path="/account" element={<pages.Account />} />
          <Route
            path="/contract-description"
            element={<pages.ContractDescription />}
          />
          <Route
            path="/req-application-form"
            element={<pages.ReqApplicationForm />}
          />
          <Route path="*" element={<pages.NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}
