/**
 * @file Layout.js
 * @description Handles the layout structure of the application, including the Navbar and Footer.
 */

import React, { useEffect, useState } from "react";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";
import "../css/App.css";

const Layout = ({ children }) => {
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const navbarElement = document.querySelector(".navbar");
    if (navbarElement) {
      setNavbarHeight(navbarElement.offsetHeight);
    }
  }, []);

  return (
    <div className="layout-container">
      <Navbar />
      {/* Dynamically push content below navbar */}
      <div
        className="layout-content"
        style={{ marginTop: `${navbarHeight}px` }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
