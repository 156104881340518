/**
 * @file ErrorBoundary.js
 * @description Fallback UI for error handling in the application.
 */

import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      errorStack: "",
      componentStack: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    console.error("🛑 Error caught in ErrorBoundary:", error);
    console.error("📌 Component Stack Trace:", errorInfo.componentStack);

    this.setState({
      errorStack: error.stack,
      componentStack: errorInfo.componentStack,
    });
  }

  handleReload = () => {
    window.location.href = "/";
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <h2 style={{ color: "red" }}>🚨 Something went wrong.</h2>
          <p>
            <strong>Error Message:</strong>{" "}
            {this.state.errorMessage || "Unknown error"}
          </p>
          <details
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "left",
              display: "inline-block",
            }}
          >
            <summary>🔍 View Error Details</summary>
            <p>
              <strong>Stack Trace:</strong>{" "}
              {this.state.errorStack || "No stack trace available"}
            </p>
            <p>
              <strong>Component Stack:</strong>{" "}
              {this.state.componentStack || "No component stack available"}
            </p>
          </details>
          <br />
          <button onClick={() => window.location.reload()}>
            🔄 Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
