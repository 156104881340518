/**
 * @file Footer.js is the footer component for the website
 * @description This component contains the footer for the website
 */
import React from "react";
import "../css/Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>© {currentYear} JNN Professional Services</p>
    </footer>
  );
};

export default Footer;
